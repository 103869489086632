import gql from "graphql-tag";

export const EVALUATEE_TO_EVALUATION_RESULT = gql`
  query performanceEvaluationResultByEvaluateeAndEvaluation($evaluatee: ID!, $evaluation: ID!) {
    performanceEvaluationResultByEvaluateeAndEvaluation(evaluatee: $evaluatee, evaluation: $evaluation) {
      totalAverageScore
      averageKpiScore
      averageCompetenceScore
      evaluatee {
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          branch {
            name
          }
          jobInfo {
            name
          }
        }
      }
      publishedEvaluators {
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      unPublishedEvaluators {
        firstName
        lastName
        profile {
          imageUrl
        }
      }
      kpiCategories {
        name
        items {
          name
          descriptions
          averageScore
          averageScoreWithWeight
        }
      }
      competenceCategories {
        name
        items {
          name
          descriptions
          averageScore
          averageScoreWithWeight
        }
      }
    }
  }
`;

export const PERFORMANCE_RESULT_EVALUATOR_BY_EVALUATION = gql`
  query performanceEvaluationResultByEvaluatorAndEvaluationAndEvaluatee(
    $evaluator: ID!
    $evaluation: ID!
    $evaluatee: ID!
  ) {
    performanceEvaluationResultByEvaluatorAndEvaluationAndEvaluatee(
      evaluator: $evaluator
      evaluation: $evaluation
      evaluatee: $evaluatee
    ) {
      evaluation {
        name
        template {
          kpiWeight
          competenceWeight
        }
        expirationBeginDate
        expirationEndDate
        validationBeginDate
        validationEndDate

        scoreSettings {
          name
          minScore
          maxScore
          scoreSettingsDescriptions {
            fromScore
            toScore
            description
          }
        }
      }
      evaluator {
        firstName
        lastName
        profile {
          imageUrl
        }
        employment {
          branch {
            name
          }
          jobInfo {
            name
          }
        }
      }
      kpiCategories {
        totalActualScore
        category {
          category {
            name
            color
            type
          }
        }
        items {
          id
          actualScore
          score
          item {
            item {
              name
              color
              description
              comment
            }
            weight
          }
        }
      }
      competenceCategories {
        totalActualScore
        category {
          category {
            name
            color
            type
          }
        }
        items {
          actualScore
          score
          item {
            item {
              name
              color
              description
              comment
            }
            weight
          }
        }
      }
      totalActualScore
      competenceTotalActualScore
      kpiTotalActualScore
    }
  }
`;
